import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const Table = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  padding: 16px 8px 8px 8px;
  width: 100%;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  background: #f4f4f4;
  border-bottom: 1px solid #e0e0e0;
  padding: 16px 8px;

  :first-of-type {
    border-top: 1px solid #e0e0e0;
  }

  h3,
  h4 {
    margin: 0;
  }

  h3 {
    flex: 2;
    font-size: 14px;
    font-weight: normal;
    text-align: left;
  }

  h4 {
    font-size: 17px;
    text-align: right;
  }
`;

export const TextInfo = styled.p`
  margin: 0;
  font-size: 13px;
  text-align: center;
`;

export const ButtonGroup = styled.div`
  font-family: "Roboto", Sans-serif;
  display: flex;
  justify-content: space-between;
`;

export const Button = styled.button.attrs({
  type: "submit",
})`
  font-size: 15px;
  font-weight: 500;
  align-self: flex-end;
  padding: 12px 24px;
  cursor: pointer;
  background: #fff;
  color: #52ba26;
  border: 1px solid #52ba26;
  border-radius: 3px;
  transition: all 0.3s ease-in-out;

  :hover {
    background: #52ba26;
    color: #fff;
    border-color: #52ba26;
  }
`;

export const ErrorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    font-size: 24px;
    color: #dc3232;
  }
`;
