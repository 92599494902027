import React from "react";
import * as CSS from "./RaportInfo.CSS";
import { useNavigate } from "react-router-dom";

export const RaportInfo = ({raportPageContent}) => {
  const navigate = useNavigate();
  const handleNewOffer = (e) => {
    e.preventDefault();

    navigate({
      pathname: "/",
    });
  };
  return (
    <CSS.Container>
      <div dangerouslySetInnerHTML={{ __html: raportPageContent }}></div>
      <CSS.Button onClick={handleNewOffer}>Wygeneruj nową ofertę</CSS.Button>
    </CSS.Container>
  );
};
