import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import axios from "axios";
import * as CSS from "../krokNForm.CSS";
import LoadingSpinner from "../../../../components/common/loadingComponent/LoadingSpinner";
import Krok1Schema from "./Krok1Schema";
import { useNavigate, createSearchParams } from "react-router-dom";

const Krok1Form = ({
  apiUrl,
  nextKrok,
  setActiveTab,
  queryParams,
  setQueryParams,
}) => {
  const [formData, setFormData] = useState({
    initial_value: null,
    choicesTypUzytkownika: [],
    choicesWojewodztwo: [],
  });
  const [iloscEnergii, setIloscEnergii] = useState("");

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  const handleNextKrok = (nextKrok) => {
    navigate({
      pathname: `${nextKrok}/`,
      search: `${createSearchParams(queryParams)}`,
    });
    setActiveTab(nextKrok);
  };
  const handleIloscEnergii = (id, choicesWojewodztwo) => {
    const intId = parseInt(id);
    const choices = choicesWojewodztwo || formData.choicesWojewodztwo;
    const matchingWojewodztwo = choices.find(
      (wojewodztwo) => wojewodztwo.id === intId
    );

    setIloscEnergii(matchingWojewodztwo?.ilosc_energii);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setIsLoading(true);

    axios
      .get(apiUrl)
      .then((response) => {
        const {
          id_oferty,
          initial_value,
          choices_typ_uzytkownika,
          choices_wojewodztwo,
        } = response.data;

        if (id_oferty) {
          queryParams.set("id_oferty", id_oferty);
          setQueryParams(queryParams);
        }

        setFormData({
          initial_value: initial_value,
          choicesTypUzytkownika: choices_typ_uzytkownika,
          choicesWojewodztwo: choices_wojewodztwo,
        });
      })
      .catch((error) => {
        setError(error.message);
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onSubmit = (values) => {
    setIsLoading(true);

    axios
      .post(apiUrl, values)
      .then((response) => {
        handleNextKrok(nextKrok);
      })
      .catch((error) => {
        setError(error.message);
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const formik = useFormik({
    initialValues: {
      typ_uzytkownika: formData?.initial_value?.typ_uzytkownika,
      wojewodztwo: formData?.initial_value?.wojewodztwo,
    },
    enableReinitialize: true,
    validationSchema: Krok1Schema,
    onSubmit,
  });

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return (
      <CSS.ErrorContainer>
        <span>
          Nastąpił problem podczas pobierania albo wysyłaniach danych. Spróbuj
          później!
        </span>
      </CSS.ErrorContainer>
    );
  }

  return (
    <>
      <CSS.FormContainer>
        <CSS.FormInnerContainer>
          <CSS.FormGroup>
            <CSS.FormGroupInner>
              <label htmlFor="typ_uzytkownika">Typ użytkownika:</label>
              <select
                name="typ_uzytkownika"
                value={formik.values.typ_uzytkownika || ""}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange("typ_uzytkownika")}
              >
                <option value="" selected disabled hidden>
                  wybierz
                </option>
                {formData?.choicesTypUzytkownika?.map((typUzytkownika) => (
                  <option
                    key={typUzytkownika.value}
                    value={typUzytkownika.value}
                  >
                    {typUzytkownika.display_name}
                  </option>
                ))}
              </select>
            </CSS.FormGroupInner>
            {formik.touched.typ_uzytkownika &&
              formik.errors.typ_uzytkownika && (
                <CSS.ErrorFormContainer>
                  <div></div>
                  <CSS.ErrorMessage>
                    {formik.errors.typ_uzytkownika}
                  </CSS.ErrorMessage>
                </CSS.ErrorFormContainer>
              )}
          </CSS.FormGroup>

          <CSS.FormGroup>
            <CSS.FormGroupInner>
              <label htmlFor="wojewodztwo">Województwo:</label>
              <select
                name="wojewodztwo"
                value={formik.values.wojewodztwo || ""}
                onBlur={formik.handleBlur}
                onChange={(event) => {
                  formik.handleChange("wojewodztwo")(event);
                  handleIloscEnergii(event.target.value);
                }}
              >
                <option value="" selected disabled hidden>
                  wybierz
                </option>
                {formData?.choicesWojewodztwo?.map((wojewodztwo) => {
                  return (
                    <option key={wojewodztwo.id} value={wojewodztwo.id}>
                      {wojewodztwo.wojewodztwo}
                    </option>
                  );
                })}
              </select>
            </CSS.FormGroupInner>
            {formik.touched.wojewodztwo && formik.errors.wojewodztwo && (
              <CSS.ErrorFormContainer>
                <div></div>
                <CSS.ErrorMessage>{formik.errors.wojewodztwo}</CSS.ErrorMessage>
              </CSS.ErrorFormContainer>
            )}
          </CSS.FormGroup>
        </CSS.FormInnerContainer>
        {iloscEnergii && (
          <CSS.InfoContainer>
            <CSS.ColumnInfo>
              <h3>Roczna suma energii promieniowania słonecznego:</h3>
              <h4>
                {iloscEnergii} <span> Wh/m²</span>
              </h4>
            </CSS.ColumnInfo>
          </CSS.InfoContainer>
        )}
      </CSS.FormContainer>

      <CSS.Button onClick={formik.handleSubmit}>Następny krok</CSS.Button>
    </>
  );
};

export default Krok1Form;
