import React from "react";
import { Routes, Route } from "react-router-dom";
import { DashboardLayout } from "../layout/DashboardLayout";
import Home from "../views/home/Home";
import TabsFotowoltaika from "../views/fotowoltaika/tabsFotowoltaika/TabsFotowoltaika";
import TabsFotowoltaikaHybryda from "../views/fotowoltaikaHybryda/tabsFotowoltaikaHybryda/TabsFotowoltaikaHybryda";

export const MainRoutes = () => {
  return (
    <Routes>
      <Route exact path="/" element={<DashboardLayout />}>
        <Route exact path="/" element={<Home />} />
        <Route path="/fw" element={<TabsFotowoltaika />}>
          <Route path="/fw/:tabId/" element={<TabsFotowoltaika />} />
        </Route>
        <Route path="/h" element={<TabsFotowoltaikaHybryda />}>
          <Route path="/h/:tabId/" element={<TabsFotowoltaikaHybryda />} />
        </Route>
        <Route
          path="*"
          element={<div>Strona o podanym adresie nie istnieje!</div>}
        />
      </Route>
    </Routes>
  );
};
