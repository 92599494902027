import React, { useState, useEffect } from "react";
import * as CSS from "./Krok5Form.CSS";
import axios from "axios";
import LoadingSpinner from "../../../../components/common/loadingComponent/LoadingSpinner";
import { createSearchParams, useNavigate } from "react-router-dom";
import ChartPlot from "../../../../components/common/chartComponent/ChartPlot";
import { useFormik } from "formik";

const Krok5Form = ({
  apiUrl,
  prevKrok,
  nextKrok,
  setActiveTab,
  queryParams,
}) => {
  const [formData, setFormData] = useState({});
  const [initialData, setInitialData] = useState({});
  const [displayData, setDisplayData] = useState({});
  const [chart, setChart] = useState(null);
  const [isMagazine, setIsMagazine] = useState(false);
  const [grant, setGrant] = useState(null);

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  const convertYearNumbertoYearsAndDaysOutput = (number) => {
    const years = Math.floor(number);
    const days = Math.round((number % 1) * 365);

    let yearsOutputString;
    const daysOutputString = days === 1 ? "dzień" : "dni";

    if (years === 1) {
      yearsOutputString = "rok";
    } else if (years <= 4) {
      yearsOutputString = "lata";
    } else {
      yearsOutputString = "lat";
    }

    if (days === 0) {
      return `${years} ${yearsOutputString}`;
    } else if (years === 0) {
      return `${days} ${daysOutputString}`;
    } else {
      return `${years} ${yearsOutputString} i ${days} ${daysOutputString}`;
    }
  };

  const formatToLocaleString = (number) => {
    return parseFloat(number).toLocaleString("pl-PL", {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });
  };

  const handlePrevKrok = () => {
    // e.preventDefault();
    navigate({
      pathname: `${prevKrok}/`,
      search: `?${createSearchParams(queryParams)}`,
    });
    setActiveTab(prevKrok);
  };

  const handleNextKrok = () => {
    // e.preventDefault();
    navigate({
      pathname: `${nextKrok}/`,
      search: `?${createSearchParams(queryParams)}`,
    });
    setActiveTab(nextKrok);
  };

  useEffect(() => {
    axios
      .get(apiUrl)
      .then((response) => {
        const { wyniki, display_data, chart, magazyn, initial_value } =
          response.data;

        setInitialData(wyniki);
        setDisplayData(display_data);
        setChart(chart);
        setIsMagazine(magazyn);
        setGrant(display_data.dotacje);
        setFormData(initial_value);

        setIsLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        console.error(error);
        setIsLoading(false);
      });
  }, []);

  const onSubmit = async (values) => {
    setIsLoading(true);

    try {
      await axios.post(apiUrl, values).then((response) => {
        setIsLoading(false);
        handleNextKrok(nextKrok);
      });
    } catch (error) {
      setError(error.message);
      console.error(error);
      setIsLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      dotacja: formData?.dotacja,
    },
    enableReinitialize: true,
    onSubmit,
  });

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return (
      <CSS.ErrorContainer>
        <span>
          Nastąpił problem podczas pobierania albo wysyłaniach danych. Spróbuj
          później!
        </span>
      </CSS.ErrorContainer>
    );
  }

  return (
    <>
      <CSS.Container>
        <CSS.Table>
          <h2>Podsumowanie</h2>
          <CSS.Row>
            <h3>Dobrana moc instalacji</h3>
            <h4>{formatToLocaleString(displayData.dobrana_moc_instalacji)} kWp</h4>
          </CSS.Row>
          <CSS.Row>
            <h3>Dobrany moduł</h3>
            <h4>{displayData.dobrany_modul}</h4>
          </CSS.Row>
          <CSS.Row>
            <h3>Dobrany falownik</h3>
            <h4>{displayData.dobrany_falownik}</h4>
          </CSS.Row>
          <CSS.Row>
            <h3>Dobrany rodzaj konstrukcji</h3>
            <h4>{displayData.dobrana_konstrukcja}</h4>
          </CSS.Row>
          {isMagazine && (
            <>
              <CSS.Row>
                <h3>Dobrany magazyn</h3>
                <h4>{displayData.dobrany_magazyn}</h4>
              </CSS.Row>
              <CSS.Row>
                <h3>Dobrana moc magazynu</h3>
                <h4>{formatToLocaleString(displayData.dobrana_moc_magazynu)} kWh</h4>
              </CSS.Row>
            </>
          )}
          <CSS.Row>
            <h3>Cena instalacji fotowoltaicznej z montażem</h3>
            <h4>{formatToLocaleString(displayData.kwota_fotowoltaika)} zł</h4>
          </CSS.Row>
          {isMagazine && (
            <CSS.Row>
              <h3>Cena magazynu z montażem</h3>
              <h4>
                {formatToLocaleString(displayData.kwota_magazyn_montaz)} zł
              </h4>
            </CSS.Row>
          )}
          <CSS.Row>
            <h3>Łączna kwota inwestycji</h3>
            <h4>{formatToLocaleString(displayData.kwota_brutto)} zł</h4>
          </CSS.Row>
          <CSS.Row>
            <h3>Energia wytworzona przez instalację PV *</h3>
            <h4>{formatToLocaleString(displayData.energia_wytworzona)} MWh</h4>
          </CSS.Row>
          <CSS.Row>
            <h3>Okres zwrotu inwestycji</h3>
            <h4>
              {convertYearNumbertoYearsAndDaysOutput(displayData.okres_zwrotu)}
            </h4>
          </CSS.Row>
          {grant && (
            <>
              <CSS.Row>
                <h3>
                  <div style={{ display: "flex", gap: 20 }}>
                    <span>
                      Uwzględnij kwotę dotacji {grant.nazwa_dotacji}":
                    </span>
                    {grant.dotacja_fotowoltaika && (
                      <span>
                        1. Instalacja PV - {formatToLocaleString(grant.dotacja_fotowoltaika)} zł
                      </span>
                    )}
                    {grant.dotacja_magazyn && (
                      <span>
                        2. Magazyn Energii - {formatToLocaleString(grant.dotacja_magazyn)} zł
                      </span>
                    )}
                  </div>
                </h3>
                <input
                  name="dotacja"
                  id="dotacja"
                  type="checkbox"
                  value={formik.values.dotacja}
                  checked={formik.values.dotacja}
                  onChange={formik.handleChange("dotacja")}
                />
              </CSS.Row>
              <CSS.Row>
                <h3>Kwota po dotacji "{grant.nazwa_dotacji}"</h3>
                <h4>{formatToLocaleString(grant.kwota_po_dotacji)} zł</h4>
              </CSS.Row>
              <CSS.Row>
                <h3>Okres zwrotu inwestycji po dofinansowaniu</h3>
                <h4>
                  {convertYearNumbertoYearsAndDaysOutput(
                    displayData.okres_zwrotu_po_dotacji
                  )}
                </h4>
              </CSS.Row>
            </>
          )}
        </CSS.Table>
        <CSS.TextInfo>
          * dla 25 letniego objętego gwarancją okresu eksploatacji
        </CSS.TextInfo>
      </CSS.Container>
      {initialData && (
        <div style={{ display: "none" }}>
          {initialData.map((wyniki, key) => (
            <div
              key={key}
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                border: "1px solid gray",
                padding: "3px",
              }}
            >
              <div>{wyniki.display_name}:</div>
              <div>{wyniki.value}</div>
            </div>
          ))}
        </div>
      )}
      {chart && <ChartPlot data={chart} />}

      <CSS.ButtonGroup>
        <CSS.Button onClick={handlePrevKrok}>Poprzedni krok</CSS.Button>
        <CSS.Button onClick={formik.handleSubmit}>Następny krok</CSS.Button>
      </CSS.ButtonGroup>
    </>
  );
};

export default Krok5Form;
