import styled from "styled-components";

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const FormInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  gap: 20px;
  width: calc(90% - 48px);

  @media screen and (max-width: 800px) {
    width: 100%;
  }
`;

export const Header = styled.h2`
  text-align: right;
  font-weight: 500;
  margin-bottom: 0;
  margin-right: 32px;
  flex: 2;

  @media screen and (max-width: 800px) {
    text-align: left;
  }
`;

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;

  label {
    flex: 2;
  }

  input,
  select {
    flex: 4;
  }

  input[type="checkbox"] {
    flex: 0;
  }

  label,
  input {
    font-size: 14px;
  }

  input,
  select {
    padding: 6px 4px;
  }

  input {
    overflow: hidden;
    padding: 6px 4px;
    border: 1px solid #adadad;
    border-radius: 4px;
  }

  label {
    margin-right: 32px;
    text-align: right;
  }

  @media screen and (max-width: 800px) {
    label {
      margin-right: 0px;
      margin-bottom: 5px;
      text-align: left;
    }
  }
`;

export const TermsContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 4;

  input[type="checkbox"] {
    min-width: 16px;
    min-height: 16px;
    margin-left: 0;
  }

  input[type="checkbox"]:hover,
  label {
    cursor: pointer;
  }

  label {
    font-size: 12px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 5px;
    text-align: left;
  }

  input,
  label {
    flex: auto;
  }
`;

export const FormGroupInner = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 800px) {
    flex-direction: column;
    align-items: normal;
  }
`;

export const ButtonGroup = styled.div`
  font-family: "Roboto", Sans-serif;
  display: flex;
  justify-content: space-between;
`;

export const Button = styled.button.attrs({
  type: "submit",
})`
  font-size: 15px;
  font-weight: 500;
  align-self: flex-end;
  padding: 12px 24px;
  cursor: pointer;
  background: #fff;
  color: #52ba26;
  border: 1px solid #52ba26;
  border-radius: 3px;
  transition: all 0.3s ease-in-out;

  :hover {
    background: #52ba26;
    color: #fff;
    border-color: #52ba26;
  }
`;

export const SubmitButtonContainer = styled.div`
  flex: 4;

  @media screen and (max-width: 800px) {
    margin-bottom: 32px;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const SubmitButton = styled(Button)`
  border-radius: 4px;
  background-color: #52ba26;
  color: #fff;
  text-transform: uppercase;
  align-self: center;

  :hover {
    background-color: #fff;
    color: #52ba26;
  }
`;

export const ErrorFormContainer = styled.div`
  display: flex;

  div {
    flex: 2;
    margin-right: 32px;

    @media screen and (max-width: 800px) {
      flex: 0;
      margin-right: 0;
    }
  }

  span {
    flex: 4;
    padding: 6px;
  }
`;

export const ErrorMessage = styled.span`
  color: #dc3232;
  font-size: 14px;
`;

export const ErrorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    font-size: 24px;
    color: #dc3232;
  }
`;
