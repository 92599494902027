import { Outlet } from "react-router-dom";
import Header from "./header/Header";
import * as CSS from "./DashboardLayout.CSS";

export const DashboardLayout = () => {
  return (
    <CSS.Container>
      <Header></Header>
      <CSS.Content>
        <Outlet />
      </CSS.Content>
    </CSS.Container>
  );
};
