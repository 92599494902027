import React from "react";
import * as CSS from "./Header.CSS";
import logo from "../../assets/logo.png";
import {Link} from "react-router-dom";

//FIXME: Logo do public

const Header = () => {
  return (
    <CSS.Header>
      <CSS.HeaderContainer>
        <CSS.LogoContainer>
          <a href="https://fotonlab.pl/" title="FotonLAB">
            <img src={logo} alt="FotonLAB" />
          </a>
        </CSS.LogoContainer>
        <CSS.ContentContainer>
          <ul style={{display: "flex", gap: "24px"}}>
            <li>
             <Link to="/">Home</Link>
            </li>
            <li>
              <a href="https://fotonlab.pl/">Powrót</a>
            </li>
          </ul>
        </CSS.ContentContainer>
      </CSS.HeaderContainer>
    </CSS.Header>
  );
};

export default Header;
