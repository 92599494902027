import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  flex: 1 1 0%;
  width: 50%;

  input {
    border-radius: 4px 0 0 4px;
    z-index: 1;
  }

  span {
    padding: 6px;
    border-radius: 0 4px 4px 0;
    background-color: #eee;
    border: 1px solid #adadad;
    border-left: none;
    height: 100%;
  }

  @media screen and (max-width: 800px) {
    width: 100%;
  }
`;
