import React from "react";

const TabContent = ({tabId, activeTab, children}) => {
 return (
   activeTab === tabId ? <>
     { children }
   </>
   : null
 );
};

export default TabContent;
