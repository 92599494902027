import * as Yup from "yup";

const phoneRegex = '^[0-9]{9}$'
const emailRegex =  '^[\\w\\.-]+@[a-zA-Z0-9-]+\\.[a-zA-Z]{2,4}$'

const Krok6Schema = Yup.object().shape({
  imie_nazwisko: Yup.string().required("To pole jest wymagane!"),
  adres_email: Yup.string()
    .matches(emailRegex, "Podaj poprawny email.")
    .required("To pole jest wymagane!"),
  numer_telefonu: Yup.string().matches(phoneRegex, "Podaj poprawny numer telefonu (9 cyfr).")
      .required("To pole jest wymagane!"),
  accept_mailing: Yup.bool().oneOf(
    [true],
    "Proszę zaakceptować warunki i zasady."
  ),
});

export default Krok6Schema;
