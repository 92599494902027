import * as Yup from "yup";

const Krok3Schema = Yup.object().shape({
  orientacja_paneli: Yup.string().required("To pole jest wymagane!"),
  miejsce_zabudowy_paneli: Yup.string().required("To pole jest wymagane!"),
  kat_nachylenia: Yup.number().when("miejsce_zabudowy_paneli", (val) => {
    if (val[0] === "dpo")
      return Yup.number().required("To pole jest wymagane!");
    return Yup.number().nullable();
  }),
  rodzaj_konstrukcji: Yup.number().required("To pole jest wymagane!"),
});

export default Krok3Schema;
