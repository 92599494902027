import React from 'react';
import ReactLoading from 'react-loading';
import * as CSS from './LoadingSpinner.CSS';
 
const LoadingSpinner = ({ type, color }) => (
    <CSS.LoadingContainer>
        <ReactLoading type={type ? type: "spin"} color={color ? color: "gray"} height={'100px'} width={'100px'} />
    </CSS.LoadingContainer>
);
 
export default LoadingSpinner;