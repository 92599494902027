import React from "react";
import * as CSS from "./TabComponents.CSS";
import { useNavigate, createSearchParams } from "react-router-dom";

const TabNavItem = ({ title, tabId, activeTab, setActiveTab, queryParams }) => {
  const navigate = useNavigate();

  const handleClick = (e) => {
    e.preventDefault();

    const currentTabIndex = parseInt(activeTab.replace("krok", ""), 10);
    const clickedTabIndex = parseInt(tabId.replace("krok", ""), 10);

    if (clickedTabIndex <= currentTabIndex) {
      navigate({
        pathname: `${tabId}/`,
        search: `?${createSearchParams(queryParams)}`,
      });

      setActiveTab(tabId);
    } else {
      console.error("You can only navigate to the previous steps.");
    }
  };

  return (
    <li className={activeTab === tabId ? "active" : null}>
      <CSS.TabLink
        onClick={handleClick}
        to={{
          pathname: `${tabId}/`,
          search: `?${createSearchParams(queryParams)}`,
        }}
      >
        {title}
      </CSS.TabLink>
    </li>
  );
};
export default TabNavItem;
