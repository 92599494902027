import React, { useEffect } from "react";
import Bokeh from "@bokeh/bokehjs/build/js/bokeh.esm.min.js";

const ChartPlot = ({ data }) => {
  useEffect(() => {
    const plot = JSON.parse(data);
    const plotElement = document.getElementById("testPlot");
    if (!plotElement.hasChildNodes()) {
      Bokeh.embed.embed_item(plot, "testPlot");
    }
  }, [data]);

  return <div id="testPlot"></div>;
};

export default ChartPlot;
