import styled from "styled-components";

export const Header = styled.div`
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  padding: 0 25px;
  background-color: #59a141;

  @media only screen and (max-width: 991px) {
    padding: 0;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
`;

export const LogoContainer = styled.div`
  padding: 0 15px;
  max-height: 50px;

  img {
    max-width: 100%;
    height: auto;
  }
`;

export const ContentContainer = styled.div`
  padding: 0 15px;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li {
    font-size: 14px;
  }

  a {
    color: #ffffff;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 500;
    transition: all 0.3s ease-in-out;

    :hover {
      color: #e2e2e2;
    }
  }
`;
