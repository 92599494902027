import * as Yup from "yup";

const patternSixIntegerAndTwoDecimalDigits = /^\d{1,6}(\.\d{1,2})?$/;

const Krok2Schema = Yup.object().shape({
  sposob_podania_zuzycia_energii: Yup.string().required(
    "To pole jest wymagane!"
  ),
  roczne_zapotrzebowanie_kwh: Yup.number().when(
    "sposob_podania_zuzycia_energii",
    (val) => {
      if (val[0] === "roczne_zapotrzebowanie")
        return Yup.number()
          .min(10, "Podaj wartość nie mniejszą od 10.")
          .required("To pole jest wymagane!");
      return Yup.number().nullable();
    }
  ),
  okres: Yup.number().when("sposob_podania_zuzycia_energii", (val) => {
    if (val[0] === "rachunek_ilosc_kwh" || val[0] === "rachunek_kwota")
      return Yup.number().required("To pole jest wymagane!");
    return Yup.number().nullable();
  }),
  okres_kwh: Yup.number().when("sposob_podania_zuzycia_energii", (val) => {
    if (val[0] === "rachunek_ilosc_kwh") {
      return Yup.number()
        .min(10, "Podaj wartość nie mniejszą od 10.")
        .required("To pole jest wymagane!")
        .test(
          "max-digits",
          "Maksymalna wartość to 6 cyfr całkowitych i 2 cyfry w częśći dziesiętnej.",
          (val) => {
            if (val !== undefined) {
              return patternSixIntegerAndTwoDecimalDigits.test(val.toString());
            }
            return true;
          }
        );
    } else {
      return Yup.number().nullable();
    }
  }),
  okres_kwota_rachunku: Yup.number().when(
    "sposob_podania_zuzycia_energii",
    (val) => {
      if (val[0] === "rachunek_kwota")
        return Yup.number()
          .min(10, "Podaj wartość nie mniejszą od 10.")
          .required("To pole jest wymagane!")
          .test(
            "max-digits",
            "Maksymalna wartość to 6 cyfr całkowitych i 2 cyfry w część dziesiętnej.",
            (val) => {
              if (val !== undefined) {
                return patternSixIntegerAndTwoDecimalDigits.test(
                  val.toString()
                );
              }
              return true;
            }
          );
      return Yup.number().nullable();
    }
  ),
  kwota_kwh: Yup.number().when("sposob_podania_zuzycia_energii", (val) => {
    if (val[0] === "rachunek_kwota")
      return Yup.number()
        .min(0, "Podaj wartość większą od 0")
        .required("To pole jest wymagane!")
        .test(
          "max-digits",
          "Maksymalna wartość to 6 cyfr całkowitych i 2 cyfry w część dziesiętnej.",
          (val) => {
            if (val !== undefined) {
              return patternSixIntegerAndTwoDecimalDigits.test(val.toString());
            }
            return true;
          }
        );
    return Yup.number().nullable();
  }),
});
export default Krok2Schema;
