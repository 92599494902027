import styled from "styled-components";

export const Container = styled.div`
    width: 100vw;
    height: 100vh;
`;

export const Content = styled.div`
    height: calc(100% - 80px);
`
