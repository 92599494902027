import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as CSS from "../krokNForm.CSS";
import axios from "axios";
import LoadingSpinner from "../../../../components/common/loadingComponent/LoadingSpinner";
import InputWithMeasure from "../../../../components/common/forms/InputWithMeasure";
import Krok2Schema from "./Krok2Schema";
import { createSearchParams, useNavigate } from "react-router-dom";

const Krok2Form = ({
  apiUrl,
  prevKrok,
  nextKrok,
  setActiveTab,
  queryParams,
}) => {
  const [initialData, setInitialData] = useState({});
  const [
    choicesSposobPodaniaZuzyciaEnergii,
    setChoicesSposobPodaniaZuzyciaEnergii,
  ] = useState([]);
  const [choicesOkres, setChoicesOkres] = useState([]);
  const [sposobPodaniaZuzyciaEnergii, setSposobPodaniaZuzyciaEnergii] =
    useState(null);
  const [roczneZapotrzebowanieKWh, setRoczneZapotrzebowanieKWh] =
    useState(null);
  const [okres, setOkres] = useState(null);
  const [okresKWh, setOkresKWh] = useState(null);
  const [okresKwotaRachunku, setOkresKwotaRachunku] = useState(null);
  const [kwotaKWh, setKwotaKWh] = useState(null);
  const [roczneZuzycieEnergii, setRoczneZuzycieEnergii] = useState(null);

  const calculateRoczneZuzycieEnergii = (
    sposob,
    roczneZapotrzebowanie,
    okres,
    okresKWh,
    okresKwota,
    kwotaKWh
  ) => {
    let zuzycieEnergii;

    if (sposob === "roczne_zapotrzebowanie" && roczneZapotrzebowanie) {
      zuzycieEnergii = roczneZapotrzebowanie;
    } else if (sposob === "rachunek_ilosc_kwh" && okres && okresKWh) {
      zuzycieEnergii = (okresKWh * 12) / okres;
    } else if (sposob === "rachunek_kwota" && okres && okresKwota && kwotaKWh) {
      if (kwotaKWh > 0) {
        zuzycieEnergii = (okresKwota * 12) / kwotaKWh / okres;
      }
    }
    zuzycieEnergii = Math.round(zuzycieEnergii);

    if (!isNaN(zuzycieEnergii)) {
      return setRoczneZuzycieEnergii(zuzycieEnergii);
    } else {
      return setRoczneZuzycieEnergii(null);
    }
  };

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  const handlePrevKrok = (e) => {
    e.preventDefault();
    navigate({
      pathname: `${prevKrok}/`,
      search: `?${createSearchParams(queryParams)}`,
    });
    setActiveTab(prevKrok);
  };

  const handleNextKrok = (nextKrok) => {
    navigate({
      pathname: `${nextKrok}/`,
      search: `?${createSearchParams(queryParams)}`,
    });
    setActiveTab(nextKrok);
  };

  const handleSposobPodaniaZuzyciaEnergii = (event) => {
    const value = event.target.value;
    setSposobPodaniaZuzyciaEnergii(value);
    calculateRoczneZuzycieEnergii(
      value,
      roczneZapotrzebowanieKWh,
      okres,
      okresKWh,
      okresKwotaRachunku,
      kwotaKWh
    );
  };

  const handleRoczneZapotzebowanieKWh = (event) => {
    const value = event.target.value;
    setRoczneZapotrzebowanieKWh(value);
    calculateRoczneZuzycieEnergii(
      sposobPodaniaZuzyciaEnergii,
      value,
      okres,
      okresKWh,
      okresKwotaRachunku,
      kwotaKWh
    );
  };
  const handleOkres = (event) => {
    const value = event.target.value;
    setOkres(value);
    calculateRoczneZuzycieEnergii(
      sposobPodaniaZuzyciaEnergii,
      roczneZapotrzebowanieKWh,
      value,
      okresKWh,
      okresKwotaRachunku,
      kwotaKWh
    );
  };
  const handleOkresKWh = (event) => {
    const value = event.target.value;
    setOkresKWh(value);
    calculateRoczneZuzycieEnergii(
      sposobPodaniaZuzyciaEnergii,
      roczneZapotrzebowanieKWh,
      okres,
      value,
      okresKwotaRachunku,
      kwotaKWh
    );
  };
  const handleOkresKwotaRachunku = (event) => {
    const value = event.target.value;
    setOkresKwotaRachunku(value);
    calculateRoczneZuzycieEnergii(
      sposobPodaniaZuzyciaEnergii,
      roczneZapotrzebowanieKWh,
      okres,
      okresKWh,
      value,
      kwotaKWh
    );
  };
  const handleKwotaKWh = (event) => {
    const value = event.target.value;
    setKwotaKWh(value);
    calculateRoczneZuzycieEnergii(
      sposobPodaniaZuzyciaEnergii,
      roczneZapotrzebowanieKWh,
      okres,
      okresKWh,
      okresKwotaRachunku,
      value
    );
  };

  useEffect(() => {
    axios
      .get(apiUrl)
      .then((response) => {
        const data = response.data;

        setInitialData(data.initial_value);
        setChoicesSposobPodaniaZuzyciaEnergii(
          data.choices_sposob_podania_zuzycia_energii
        );
        setChoicesOkres(data.choices_okres);

        setSposobPodaniaZuzyciaEnergii(
          data.initial_value.sposob_podania_zuzycia_energii
        );
        setRoczneZapotrzebowanieKWh(
          data.initial_value.roczne_zapotrzebowanie_kwh
        );

        setOkres(data.initial_value.okres);
        setOkresKWh(data.initial_value.okres_kwh);
        setOkresKwotaRachunku(data.initial_value.okres_kwota_rachunku);
        setKwotaKWh(data.initial_value.kwota_kwh);

        calculateRoczneZuzycieEnergii(
          data.initial_value.sposob_podania_zuzycia_energii,
          data.initial_value.roczne_zapotrzebowanie_kwh,
          data.initial_value.okres,
          data.initial_value.okres_kwh,
          data.initial_value.okres_kwota_rachunku,
          data.initial_value.kwota_kwh
        );
        setIsLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        console.error(error);
        setIsLoading(false);
      });
  }, []);

  const onSubmit = async (values) => {
    setIsLoading(true);

    try {
      await axios.post(apiUrl, values).then((response) => {
        setIsLoading(false);
        handleNextKrok(nextKrok);
      });
    } catch (error) {
      setError(error.message);
      console.error(error);
      setIsLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      sposob_podania_zuzycia_energii:
        initialData.sposob_podania_zuzycia_energii,
      roczne_zapotrzebowanie_kwh: initialData.roczne_zapotrzebowanie_kwh,
      okres: initialData.okres,
      okres_kwh: initialData.okres_kwh,
      okres_kwota_rachunku: initialData.okres_kwota_rachunku,
      kwota_kwh: initialData.kwota_kwh,
    },
    enableReinitialize: true,
    validationSchema: Krok2Schema,
    onSubmit,
  });

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return (
      <CSS.ErrorContainer>
        <span>
          Nastąpił problem podczas pobierania albo wysyłaniach danych. Spróbuj
          później!
        </span>
      </CSS.ErrorContainer>
    );
  }

  return (
    <>
      <CSS.FormContainer>
        <CSS.FormInnerContainer>
          <CSS.FormGroup>
            <CSS.FormGroupInner>
              <label htmlFor="sposob_podania_zuzycia_energii">
                Sposób podania zużycia energii:
              </label>
              <select
                name="sposob_podania_zuzycia_energii"
                value={formik.values.sposob_podania_zuzycia_energii || ""}
                onBlur={formik.handleBlur}
                onChange={(event) => {
                  formik.handleChange("sposob_podania_zuzycia_energii")(event);
                  handleSposobPodaniaZuzyciaEnergii(event);
                }}
              >
                <option value="">wybierz</option>
                {choicesSposobPodaniaZuzyciaEnergii.map((sposob) => (
                  <option key={sposob.value} value={sposob.value}>
                    {sposob.display_name}
                  </option>
                ))}
              </select>
            </CSS.FormGroupInner>
            {formik.touched.sposob_podania_zuzycia_energii &&
            formik.errors.sposob_podania_zuzycia_energii ? (
              <CSS.ErrorFormContainer>
                <div></div>
                <CSS.ErrorMessage>
                  {formik.errors.sposob_podania_zuzycia_energii}
                </CSS.ErrorMessage>
              </CSS.ErrorFormContainer>
            ) : null}
          </CSS.FormGroup>

          {formik.values.sposob_podania_zuzycia_energii ===
            "roczne_zapotrzebowanie" && (
            <CSS.FormGroup>
              <CSS.FormGroupInner>
                <label htmlFor="roczne_zapotrzebowanie_kwh">
                  Roczne zapotrzebowanie kWh:
                </label>
                <InputWithMeasure
                  measure="kWh"
                  name="roczne_zapotrzebowanie_kwh"
                  min="10"
                  value={formik.values.roczne_zapotrzebowanie_kwh}
                  onBlur={formik.handleBlur}
                  onChange={(event) => {
                    formik.handleChange("roczne_zapotrzebowanie_kwh")(event);
                    handleRoczneZapotzebowanieKWh(event);
                  }}
                />
              </CSS.FormGroupInner>
              {formik.touched.roczne_zapotrzebowanie_kwh &&
              formik.errors.roczne_zapotrzebowanie_kwh ? (
                <CSS.ErrorFormContainer>
                  <div></div>
                  <CSS.ErrorMessage>
                    {formik.errors.roczne_zapotrzebowanie_kwh}
                  </CSS.ErrorMessage>
                </CSS.ErrorFormContainer>
              ) : null}
            </CSS.FormGroup>
          )}

          {formik.values.sposob_podania_zuzycia_energii ===
            "rachunek_ilosc_kwh" && (
            <>
              <CSS.FormGroup>
                <CSS.FormGroupInner>
                  <label htmlFor="okres">
                    Okres jaki pokrywa rachunek za energię:
                  </label>
                  <select
                    name="okres"
                    value={formik.values.okres || ""}
                    onBlur={formik.handleBlur}
                    onChange={(event) => {
                      formik.handleChange("okres")(event);
                      handleOkres(event);
                    }}
                  >
                    <option value="">wybierz</option>
                    {choicesOkres.map((okres) => (
                      <option key={okres.value} value={okres.value}>
                        {okres.display_name}
                      </option>
                    ))}
                  </select>
                </CSS.FormGroupInner>
                {formik.touched.okres && formik.errors.okres ? (
                  <CSS.ErrorFormContainer>
                    <div></div>
                    <CSS.ErrorMessage>{formik.errors.okres}</CSS.ErrorMessage>
                  </CSS.ErrorFormContainer>
                ) : null}
              </CSS.FormGroup>

              <CSS.FormGroup>
                <CSS.FormGroupInner>
                  <label htmlFor="okres_kwh">
                    Zużycie energii elektrycznej w wybranym okresie:
                  </label>
                  <InputWithMeasure
                    measure="kWh"
                    name="okres_kwh"
                    min="10"
                    value={formik.values.okres_kwh}
                    onBlur={formik.handleBlur}
                    onChange={(event) => {
                      formik.handleChange("okres_kwh")(event);
                      handleOkresKWh(event);
                    }}
                  />
                </CSS.FormGroupInner>
                {formik.touched.okres_kwh && formik.errors.okres_kwh ? (
                  <CSS.ErrorFormContainer>
                    <div></div>
                    <CSS.ErrorMessage>
                      {formik.errors.okres_kwh}
                    </CSS.ErrorMessage>
                  </CSS.ErrorFormContainer>
                ) : null}
              </CSS.FormGroup>
            </>
          )}
          {formik.values.sposob_podania_zuzycia_energii ===
            "rachunek_kwota" && (
            <>
              <CSS.FormGroup>
                <CSS.FormGroupInner>
                  <label htmlFor="okres">
                    Okres jaki pokrywa rachunek za energię:
                  </label>
                  <select
                    name="okres"
                    value={formik.values.okres || ""}
                    onBlur={formik.handleBlur}
                    onChange={(event) => {
                      formik.handleChange("okres")(event);
                      handleOkres(event);
                    }}
                  >
                    <option value="">wybierz</option>
                    {choicesOkres.map((okres) => (
                      <option key={okres.value} value={okres.value}>
                        {okres.display_name}
                      </option>
                    ))}
                  </select>
                </CSS.FormGroupInner>
                {formik.touched.okres && formik.errors.okres ? (
                  <CSS.ErrorFormContainer>
                    <div></div>
                    <CSS.ErrorMessage>{formik.errors.okres}</CSS.ErrorMessage>
                  </CSS.ErrorFormContainer>
                ) : null}
              </CSS.FormGroup>

              <CSS.FormGroup>
                <CSS.FormGroupInner>
                  <label htmlFor="okres_kwota_rachunku">
                    Kwota brutto z rachunku w wybranym okresie:
                  </label>
                  <InputWithMeasure
                    measure="zł"
                    name="okres_kwota_rachunku"
                    min="10"
                    step="0.01"
                    value={formik.values.okres_kwota_rachunku}
                    onBlur={formik.handleBlur}
                    onChange={(event) => {
                      formik.handleChange("okres_kwota_rachunku")(event);
                      handleOkresKwotaRachunku(event);
                    }}
                  />
                </CSS.FormGroupInner>
                {formik.touched.okres_kwota_rachunku &&
                formik.errors.okres_kwota_rachunku ? (
                  <CSS.ErrorFormContainer>
                    <div></div>
                    <CSS.ErrorMessage>
                      {formik.errors.okres_kwota_rachunku}
                    </CSS.ErrorMessage>
                  </CSS.ErrorFormContainer>
                ) : null}
              </CSS.FormGroup>

              <CSS.FormGroup>
                <CSS.FormGroupInner>
                  <label htmlFor="kwota_kwh">
                    Kwota 1kWh z rachunku w wybranym okresie:
                  </label>
                  <InputWithMeasure
                    measure="zł"
                    name="kwota_kwh"
                    min="0.1"
                    step="0.01"
                    value={formik.values.kwota_kwh}
                    onBlur={formik.handleBlur}
                    onChange={(event) => {
                      formik.handleChange("kwota_kwh")(event);
                      handleKwotaKWh(event);
                    }}
                  />
                </CSS.FormGroupInner>
                {formik.touched.kwota_kwh && formik.errors.kwota_kwh ? (
                  <CSS.ErrorFormContainer>
                    <div></div>
                    <CSS.ErrorMessage>
                      {formik.errors.kwota_kwh}
                    </CSS.ErrorMessage>
                  </CSS.ErrorFormContainer>
                ) : null}
              </CSS.FormGroup>
            </>
          )}
        </CSS.FormInnerContainer>

        {roczneZuzycieEnergii && roczneZuzycieEnergii > 0 ? (
          <CSS.InfoContainer>
            <CSS.ColumnInfo>
              <h3>Roczne zużycie energii:</h3>
              <h4>
                {roczneZuzycieEnergii}
                <span> kWh</span>
              </h4>
            </CSS.ColumnInfo>
          </CSS.InfoContainer>
        ) : null}
      </CSS.FormContainer>

      <CSS.ButtonGroup>
        <CSS.Button onClick={handlePrevKrok}>Poprzedni krok</CSS.Button>
        <CSS.Button onClick={formik.handleSubmit}>Następny krok</CSS.Button>
      </CSS.ButtonGroup>
    </>
  );
};

export default Krok2Form;
