import React, { useState } from "react";
import {
  useSearchParams,
  createSearchParams,
  useParams,
} from "react-router-dom";
import * as CSS from "../../../components/common/tabsComponents/TabComponents.CSS";
import TabsContainer from "../../../components/common/tabsComponents/TabsContainer";
import TabNavItem from "../../../components/common/tabsComponents/TabNavItem";
import TabContent from "../../../components/common/tabsComponents/TabContent";
import Krok1Form from "../formsFotowoltaika/krok1Form/Krok1Form";
import Krok2Form from "../formsFotowoltaika/krok2Form/Krok2Form";
import Krok3Form from "../formsFotowoltaika/krok3Form/Krok3Form";
import Krok4Form from "../formsFotowoltaika/krok4Form/Krok4Form";
import Krok5Form from "../formsFotowoltaika/krok5Form/Krok5Form";
import Krok6Form from "../formsFotowoltaika/krok6Form/Krok6Form";

const TabsFotowoltaika = () => {
  let { tabId } = useParams();
  tabId = tabId ? tabId : "krok1";

  const [queryParams, setQueryParams] = useSearchParams();
  const apiUrl = `${process.env.REACT_APP_API_URL}${tabId}/?${createSearchParams(queryParams)}`;

  const [activeTab, setActiveTab] = useState(tabId);

  return (
    <TabsContainer>
      <CSS.TabNav>
        <TabNavItem
          title="Dane użytkownika"
          tabId="krok1"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          queryParams={queryParams}
        />
        <TabNavItem
          title="Zużycie energii"
          tabId="krok2"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          queryParams={queryParams}
        />
        <TabNavItem
          title="Zabudowa paneli"
          tabId="krok3"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          queryParams={queryParams}
        />
        <TabNavItem
          title="Parametry instalacji"
          tabId="krok4"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          queryParams={queryParams}
        />
        <TabNavItem
          title="Analiza opłacalności"
          tabId="krok5"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          queryParams={queryParams}
        />
        <TabNavItem
          title="Generowanie oferty"
          tabId="krok6"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          queryParams={queryParams}
        />
      </CSS.TabNav>
      <CSS.TabsContentContainer>
        <TabContent tabId="krok1" activeTab={activeTab}>
          <Krok1Form
            apiUrl={apiUrl}
            nextKrok="krok2"
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            queryParams={queryParams}
            setQueryParams={setQueryParams}
          />
        </TabContent>
        <TabContent tabId="krok2" activeTab={activeTab}>
          <Krok2Form
            apiUrl={apiUrl}
            prevKrok="krok1"
            nextKrok="krok3"
            setActiveTab={setActiveTab}
            queryParams={queryParams}
          />
        </TabContent>
        <TabContent tabId="krok3" activeTab={activeTab}>
          <Krok3Form
            apiUrl={apiUrl}
            prevKrok="krok2"
            nextKrok="krok4"
            setActiveTab={setActiveTab}
            queryParams={queryParams}
          />
        </TabContent>
        <TabContent tabId="krok4" activeTab={activeTab}>
          <Krok4Form
            apiUrl={apiUrl}
            prevKrok="krok3"
            nextKrok="krok5"
            setActiveTab={setActiveTab}
            queryParams={queryParams}
          />
        </TabContent>
        <TabContent tabId="krok5" activeTab={activeTab}>
          <Krok5Form
            apiUrl={apiUrl}
            prevKrok="krok4"
            nextKrok="krok6"
            setActiveTab={setActiveTab}
            queryParams={queryParams}
          />
        </TabContent>
        <TabContent tabId="krok6" activeTab={activeTab}>
          <Krok6Form
            apiUrl={apiUrl}
            prevKrok="krok5"
            setActiveTab={setActiveTab}
            queryParams={queryParams}
          />
        </TabContent>
      </CSS.TabsContentContainer>
    </TabsContainer>
  );
};
export default TabsFotowoltaika;
