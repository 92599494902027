import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as CSS from "../krokNForm.CSS";
import axios from "axios";
import LoadingSpinner from "../../../../components/common/loadingComponent/LoadingSpinner";
import InputWithMeasure from "../../../../components/common/forms/InputWithMeasure";
import Krok4Schema from "./Krok4Schema";
import { createSearchParams, useNavigate } from "react-router-dom";

const Krok4Form = ({
  apiUrl,
  prevKrok,
  nextKrok,
  setActiveTab,
  queryParams,
}) => {
  const [initialData, setInitialData] = useState({});
  const [choicesTypModulu, setChoicesTypModulu] = useState([]);
  const [choicesEditInstallation, setChoicesEditInstallation] = useState([]);
  const [choicesTypFalownika, setChoicesTypFalownika] = useState([]);
  const [choicesMagazynEnergii, setChoicesMagazynEnergii] = useState([]);
  const [iloscPaneliUzytkownik, setIloscPaneliUzytkownik] = useState(null);
  const [iloscPaneli, setIloscPaneli] = useState(null);
  const [mocSzczytowaInstalacji, setMocSzczytowaInstalacji] = useState(null);
  const [
    mocSzczytowaInstalacjiUzytkownik,
    setMocSzczytowaInstalacjiUzytkownik,
  ] = useState(null);
  const [editInstallation, setEditInstallation] = useState(null);
  const [magazynEnergii, setMagazynEnergii] = useState(null);
  const [mocMagazynuEnergii, setMocMagazynuEnergii] = useState(null);
  const [choicesTypMagazynu, setChoicesTypMagazynu] = useState([]);

  const [customizedPricingMessage, setCustomizedPricingMessage] =
    useState(null);
  const [timer, setTimer] = useState(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  const handlePrevKrok = (e) => {
    e.preventDefault();
    navigate({
      pathname: `${prevKrok}/`,
      search: `?${createSearchParams(queryParams)}`,
    });
    setActiveTab(prevKrok);
  };

  const handleNextKrok = (nextKrok) => {
    navigate({
      pathname: `${nextKrok}/`,
      search: `?${createSearchParams(queryParams)}`,
    });
    setActiveTab(nextKrok);
  };

  const handleTypModulu = (e) => {
    const value = e.target.value;

    formik.setFieldValue("typ_modulu", value);
    onSubmitTypModulu(value);
  };

  const handleTypFalownika = (e) => {
    const value = e.target.value;

    formik.setFieldValue("typ_falownika", value);
    onSubmitTypFalownika(value);
  };

  const handleTypMagazynu = (e) => {
    const value = e.target.value;

    formik.setFieldValue("typ_magazynu", value);
  };

  const handleMocMagazynuEnergii = (value, choicesMagazyn) => {
    const valueInt = parseInt(value);

    const choices = choicesMagazyn || choicesTypMagazynu;
    const foundTyp = choices.find((typ) => typ.id === valueInt);

    setMocMagazynuEnergii(foundTyp?.storage_capacity);
  };

  const handleEditInstallation = (e) => {
    let value = e.target.value;
    let stringToBoolean = value == "true" ? true : false;

    formik.setFieldValue("edit_installation", value);
    setEditInstallation(stringToBoolean);

    onSubmitEditInstallation(stringToBoolean);
  };

  const handleMagazynEnergii = (e) => {
    let value = e.target.value;
    let stringToBoolean = value == "true" ? true : false;

    formik.setFieldValue("magazyn_energii", value);
    setMagazynEnergii(stringToBoolean);

    onSubmitMagazynEnergii(stringToBoolean);
  };

  const handleIloscPaneliUzytkownik = (event) => {
    let value = event.target.value;
    setIloscPaneliUzytkownik(value);

    changeDelay(value);
  };

  // opóznienie na inpucie przy zmianie ilości paneli
  const changeDelay = (change) => {
    if (timer) {
      clearTimeout(timer);
      setTimer(null);
    }
    setTimer(
      setTimeout(() => {
        onSubmitIloscPaneliUzytkownik(change);
        setTimer(null);
      }, 1000)
    );
  };
  // disabled na przyciskach podczas trwania opóźnienia na inpucie
  useEffect(() => {
    if (timer !== null) {
      setIsButtonDisabled(true);
    } else {
      setIsButtonDisabled(false);
    }
  }, [timer]);

  // dla selecta przypisuje odpowiedni falownik
  const changeTypFalownika = (choices_typ_falownika) => {
    const foundTyp = choices_typ_falownika.find(
      (typ) => typ.id == formik.values.typ_falownika
    );

    if (foundTyp) {
      formik.setFieldValue("typ_falownika", foundTyp.id);
    } else {
      formik.setFieldValue("typ_falownika", "");
    }
  };

  const changeTypMagazynu = (choices_typ_magazynu) => {
    const foundTyp = choices_typ_magazynu.find(
      (typ) => typ.id == formik.values.typ_magazynu
    );

    if (foundTyp) {
      formik.setFieldValue("typ_magazynu", foundTyp.id);
      setMocMagazynuEnergii(foundTyp.storage_capacity);
    } else {
      formik.setFieldValue("typ_magazynu", "");
      setMocMagazynuEnergii(null);
    }
  };

  useEffect(() => {
    axios
      .get(apiUrl)
      .then((response) => {
        const data = response.data;

        setInitialData(data.initial_value);
        setChoicesTypModulu(data.choices_typ_modulu);
        setChoicesEditInstallation(data.choices_edit_installation);
        setChoicesTypFalownika(data.choices_typ_falownika);
        setChoicesMagazynEnergii(data.choices_magazyn_energii);
        changeTypFalownika(data.choices_typ_falownika);
        if (data.choices_typ_magazynu) {
          setChoicesTypMagazynu(data.choices_typ_magazynu);
          changeTypMagazynu(data.choices_typ_magazynu);
          handleMocMagazynuEnergii(
            data.initial_value.typ_magazynu,
            data.choices_typ_magazynu
          );
        }
        setIloscPaneliUzytkownik(data.initial_value.ilosc_modulow_uzytkownik);

        // Initial type values
        formik.setFieldValue("typ_magazynu", data.initial_value.typ_magazynu);
        formik.setFieldValue("typ_modulu", data.initial_value.typ_modulu);

        setMocSzczytowaInstalacji(data.initial_value.moc_szczytowa_instalacji);
        setMocSzczytowaInstalacjiUzytkownik(
          data.initial_value.moc_szczytowa_instalacji_uzytkownik
        );
        setIloscPaneli(data.initial_value.proponowana_ilosc_modulow);

        // Boolean initial values
        setEditInstallation(data.initial_value.edit_installation);
        setMagazynEnergii(data.initial_value.magazyn_energii);

        setCustomizedPricingMessage(data.tresc_niestandardowa_wycena);

        setIsLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        console.error(error);
        setIsLoading(false);
      });
  }, []);

  const onSubmitTypModulu = async (value_typ_modulu) => {
    if (value_typ_modulu === "") return;

    value_typ_modulu = { typ_modulu_data: value_typ_modulu };
    try {
      await axios.post(apiUrl, value_typ_modulu).then((response) => {
        const data = response.data;

        setMocSzczytowaInstalacji(data.moc_szczytowa_instalacji);
        setIloscPaneli(data.ilosc_modulow);
        setChoicesTypFalownika(data.choices_typ_falownika);
        changeTypFalownika(data.choices_typ_falownika);

        if (data.moc_szczytowa_instalacji_uzytkownik) {
          setMocSzczytowaInstalacjiUzytkownik(
            data.moc_szczytowa_instalacji_uzytkownik
          );
        }
      });
    } catch (error) {
      setError(error.message);
      console.error(error);
    }
  };

  const onSubmitTypFalownika = async (value_typ_falownika) => {
    if (value_typ_falownika === "") return;

    value_typ_falownika = { typ_falownika_data: value_typ_falownika };
    try {
      await axios.post(apiUrl, value_typ_falownika).then((response) => {
        const data = response.data;

        setChoicesTypMagazynu(data.choices_typ_magazynu);
        changeTypMagazynu(data.choices_typ_magazynu);
        // setMocSzczytowaInstalacji(data.moc_szczytowa_instalacji);
        // setIloscPaneli(data.ilosc_modulow);
        // setChoicesTypFalownika(data.choices_typ_falownika);
        // changeTypFalownika(data.choices_typ_falownika);
        //
        // if (data.moc_szczytowa_instalacji_uzytkownik) {
        //   setMocSzczytowaInstalacjiUzytkownik(
        //     data.moc_szczytowa_instalacji_uzytkownik
        //   );
        // }
      });
    } catch (error) {
      setError(error.message);
      console.error(error);
    }
  };

  const onSubmitIloscPaneliUzytkownik = async (
    value_ilosc_paneli_uzytkownik
  ) => {
    value_ilosc_paneli_uzytkownik = {
      ilosc_paneli_uzytkownik_data: value_ilosc_paneli_uzytkownik,
    };
    try {
      await axios
        .post(apiUrl, value_ilosc_paneli_uzytkownik)
        .then((response) => {
          const data = response.data;

          setChoicesTypFalownika(data.choices_typ_falownika);
          changeTypFalownika(data.choices_typ_falownika);
          setMocSzczytowaInstalacjiUzytkownik(
            data.moc_szczytowa_instalacji_uzytkownik
          );
          if (data.choices_typ_magazynu) {
            setChoicesTypMagazynu(data.choices_typ_magazynu);
            changeTypMagazynu(data.choices_typ_magazynu);
          }
        });
    } catch (error) {
      setError(error.message);
      console.error(error);
    }
  };

  const onSubmitEditInstallation = async (value_edit_installation) => {
    value_edit_installation = {
      edit_installation_data: value_edit_installation,
    };
    try {
      await axios.post(apiUrl, value_edit_installation).then((response) => {
        const data = response.data;

        setIloscPaneliUzytkownik(data.ilosc_paneli_uzytkownik);
        formik.setFieldValue(
          "ilosc_modulow_uzytkownik",
          data.ilosc_paneli_uzytkownik
        );

        setMocSzczytowaInstalacjiUzytkownik(data.moc_szczytowa_uzytkownik);
        setChoicesTypFalownika(data.choices_typ_falownika);
        changeTypFalownika(data.choices_typ_falownika);

        setChoicesTypMagazynu(data.choices_typ_magazynu);
        changeTypMagazynu(data.choices_typ_magazynu);
      });
    } catch (error) {
      setError(error.message);
      console.error(error);
    }
  };

  const onSubmitMagazynEnergii = async (value_magazyn_energii) => {
    value_magazyn_energii = {
      magazyn_energii_data: value_magazyn_energii,
    };
    try {
      await axios.post(apiUrl, value_magazyn_energii).then((response) => {
        const data = response.data;

        setChoicesTypMagazynu(data.choices_typ_magazynu);
        changeTypMagazynu(data.choices_typ_magazynu);
      });
    } catch (error) {
      setError(error.message);
      console.error(error);
    }
  };

  const onSubmit = async (values) => {
    setIsLoading(true);

    values.edit_installation = Boolean(values.edit_installation);
    values.magazyn_energii = Boolean(values.magazyn_energii);
    values.proponowana_ilosc_modulow = iloscPaneli;

    try {
      await axios.post(apiUrl, values).then((response) => {
        setIsLoading(false);
        handleNextKrok(nextKrok);
      });
    } catch (error) {
      setError(error.message);
      console.error(error);
      setIsLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      typ_modulu: initialData.typ_modulu,
      edit_installation: initialData.edit_installation,
      proponowana_ilosc_modulow: initialData.proponowana_ilosc_modulow,
      ilosc_modulow_uzytkownik: initialData.ilosc_modulow_uzytkownik,
      typ_falownika: initialData.typ_falownika,
      magazyn_energii: initialData.magazyn_energii,
      typ_magazynu: initialData.typ_magazynu,
      proponowana_moc_instalacji: initialData.proponowana_moc_instalacji,
    },
    enableReinitialize: true,
    validationSchema: Krok4Schema,
    onSubmit,
  });

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return (
      <CSS.ErrorContainer>
        <span>
          Nastąpił problem podczas pobierania albo wysyłaniach danych. Spróbuj
          później!
        </span>
      </CSS.ErrorContainer>
    );
  }

  return (
    <>
      <CSS.FormContainer>
        <CSS.FormInnerContainer>
          <CSS.FormGroup>
            <CSS.FormGroupInner>
              <label htmlFor="typ_modulu">Moduł fotowoltaiczny:</label>
              <select
                name="typ_modulu"
                value={formik.values.typ_modulu}
                onBlur={formik.handleBlur}
                onChange={(event) => {
                  formik.handleChange("typ_modulu")(event);
                  handleTypModulu(event);
                }}
              >
                {choicesTypModulu.map((typModulu) => (
                  <option key={typModulu.value} value={typModulu.value}>
                    {typModulu.display_name}
                  </option>
                ))}
              </select>
            </CSS.FormGroupInner>
            {formik.errors.typ_modulu ? (
              <CSS.ErrorFormContainer>
                <div></div>
                <CSS.ErrorMessage>{formik.errors.typ_modulu}</CSS.ErrorMessage>
              </CSS.ErrorFormContainer>
            ) : null}
          </CSS.FormGroup>

          <CSS.FormGroup>
            <CSS.FormGroupInner>
              <label htmlFor="typ_falownika">Typ falownika:</label>
              <select
                name="typ_falownika"
                id="typ_falownika"
                value={formik.values.typ_falownika}
                onBlur={formik.handleBlur}
                onChange={(event) => {
                  formik.handleChange("typ_falownika");
                  handleTypFalownika(event);
                }}
              >
                <option value="">wybierz</option>
                {choicesTypFalownika?.map((falownik) => {
                  return (
                    <option key={falownik.id} value={falownik.id}>
                      {falownik.display_name}
                    </option>
                  );
                })}
              </select>
            </CSS.FormGroupInner>
            {formik.errors.typ_falownika ? (
              <CSS.ErrorFormContainer>
                <div></div>
                <CSS.ErrorMessage>
                  {formik.errors.typ_falownika}
                </CSS.ErrorMessage>
              </CSS.ErrorFormContainer>
            ) : null}
            {choicesTypFalownika.length === 0 ? (
              <CSS.ErrorFormContainer>
                <div></div>
                <CSS.ErrorMessage>{customizedPricingMessage}</CSS.ErrorMessage>
              </CSS.ErrorFormContainer>
            ) : null}
          </CSS.FormGroup>

          {choicesMagazynEnergii && (
            <CSS.FormGroup>
              <CSS.FormGroupInner>
                <label htmlFor="magazyn_energii">Magazyn energii:</label>
                <select
                  name="magazyn_energii"
                  value={formik.values.magazyn_energii}
                  onBlur={formik.handleBlur}
                  onChange={(event) => {
                    formik.handleChange("magazyn_energii");
                    handleMagazynEnergii(event);
                  }}
                >
                  {choicesMagazynEnergii?.map((magazyn) => {
                    return (
                      <option key={magazyn.value} value={magazyn.value}>
                        {magazyn.display_name}
                      </option>
                    );
                  })}
                </select>
              </CSS.FormGroupInner>
              {formik.errors.magazyn_energii ? (
                <CSS.ErrorFormContainer>
                  <div></div>
                  <CSS.ErrorMessage>
                    {formik.errors.magazyn_energii}
                  </CSS.ErrorMessage>
                </CSS.ErrorFormContainer>
              ) : null}
            </CSS.FormGroup>
          )}

          {formik.values.typ_falownika && magazynEnergii && (
            <CSS.FormGroup>
              <CSS.FormGroupInner>
                <label htmlFor="typ_magazynu">Typ magazynu:</label>
                <select
                  name="typ_magazynu"
                  id="typ_magazynu"
                  value={formik.values.typ_magazynu}
                  onBlur={formik.handleBlur}
                  onChange={(event) => {
                    formik.handleChange("typ_magazynu");
                    handleTypMagazynu(event);
                    handleMocMagazynuEnergii(event.target.value);
                  }}
                >
                  <option value="">wybierz</option>
                  {choicesTypMagazynu?.map((magazyn) => {
                    return (
                      <option key={magazyn.id} value={magazyn.id}>
                        {magazyn.display_name}
                      </option>
                    );
                  })}
                </select>
              </CSS.FormGroupInner>
              {formik.errors.typ_magazynu ? (
                <CSS.ErrorFormContainer>
                  <div></div>
                  <CSS.ErrorMessage>
                    {formik.errors.typ_magazynu}
                  </CSS.ErrorMessage>
                </CSS.ErrorFormContainer>
              ) : null}
              {!!formik.values.typ_falownika && !choicesTypMagazynu.length ? (
                <CSS.ErrorFormContainer>
                  <div></div>
                  <CSS.ErrorMessage>
                    {customizedPricingMessage}
                  </CSS.ErrorMessage>
                </CSS.ErrorFormContainer>
              ) : null}
            </CSS.FormGroup>
          )}
          <CSS.FormGroup>
            <CSS.FormGroupInner>
              <label htmlFor="edit_installation">
                Czy chcesz zmodyfikować dobraną instalację?:
              </label>
              <select
                name="edit_installation"
                value={formik.values.edit_installation}
                onBlur={formik.handleBlur}
                onChange={(event) => {
                  formik.handleChange("edit_installation")(event);
                  handleEditInstallation(event);
                }}
              >
                {choicesEditInstallation.map((edit) => (
                  <option key={edit.value} value={edit.value}>
                    {edit.display_name}
                  </option>
                ))}
              </select>
            </CSS.FormGroupInner>
            {formik.errors.edit_installation ? (
              <CSS.ErrorFormContainer>
                <div></div>
                <CSS.ErrorMessage>
                  {formik.errors.edit_installation}
                </CSS.ErrorMessage>
              </CSS.ErrorFormContainer>
            ) : null}
          </CSS.FormGroup>

          {editInstallation && (
            <>
              <CSS.FormGroup>
                <CSS.FormGroupInner>
                  <label htmlFor="ilosc_modulow_uzytkownik">
                    Ilośc modułów:
                  </label>
                  <InputWithMeasure
                    measure="szt."
                    name="ilosc_modulow_uzytkownik"
                    min="1"
                    step="1"
                    value={formik.values.ilosc_modulow_uzytkownik}
                    onBlur={formik.handleBlur}
                    onChange={(event) => {
                      formik.handleChange("ilosc_modulow_uzytkownik")(event);
                      handleIloscPaneliUzytkownik(event);
                    }}
                  />
                </CSS.FormGroupInner>
                {formik.errors.ilosc_modulow_uzytkownik ? (
                  <CSS.ErrorFormContainer>
                    <div></div>
                    <CSS.ErrorMessage>
                      {formik.errors.ilosc_modulow_uzytkownik}
                    </CSS.ErrorMessage>
                  </CSS.ErrorFormContainer>
                ) : null}
                {!choicesTypFalownika.length ||
                (!!formik.values.typ_falownika &&
                  magazynEnergii &&
                  !choicesTypMagazynu.length) ? (
                  <CSS.ErrorFormContainer>
                    <div></div>
                    <CSS.ErrorMessage>
                      Proszę zmniejszyć ilość paneli.
                    </CSS.ErrorMessage>
                  </CSS.ErrorFormContainer>
                ) : null}
              </CSS.FormGroup>
            </>
          )}
        </CSS.FormInnerContainer>

        {editInstallation ? (
          <CSS.InfoContainer>
            <CSS.RowInfo>
              <h2>Nasz dobór</h2>
              <h2>Twój dobór</h2>
            </CSS.RowInfo>
            <CSS.H3TextLeft>Ilość modułów:</CSS.H3TextLeft>
            <CSS.RowInfo>
              <h4>
                {iloscPaneli}
                <span> szt.</span>
              </h4>
              <h4>
                {iloscPaneliUzytkownik}
                <span> szt.</span>
              </h4>
            </CSS.RowInfo>
            <CSS.H3TextLeft>Moc szczytowa instalacji:</CSS.H3TextLeft>
            <CSS.RowInfo>
              <h4>
                {mocSzczytowaInstalacji}
                <span> kWp</span>
              </h4>
              <h4>
                {mocSzczytowaInstalacjiUzytkownik}
                <span> kWp</span>
              </h4>
            </CSS.RowInfo>
            {magazynEnergii && mocMagazynuEnergii && (
              <>
                <CSS.H3TextLeft>Moc magazynu energii:</CSS.H3TextLeft>
                <CSS.RowInfo>
                  <h4>
                    {mocMagazynuEnergii}
                    <span> kWh</span>
                  </h4>
                </CSS.RowInfo>
              </>
            )}
          </CSS.InfoContainer>
        ) : null}

        {!editInstallation && iloscPaneli && mocSzczytowaInstalacji ? (
          <CSS.InfoContainer>
            <CSS.ColumnInfo>
              <h3>Ilość modułów:</h3>
              <h4>
                {iloscPaneli}
                <span> szt.</span>
              </h4>
            </CSS.ColumnInfo>
            <CSS.ColumnInfo>
              <h3>Moc szczytowa instalacji:</h3>
              <h4>
                {mocSzczytowaInstalacji}
                <span> kWp</span>
              </h4>
            </CSS.ColumnInfo>
            {magazynEnergii && mocMagazynuEnergii && (
              <CSS.ColumnInfo>
                <h3>Moc magazynu energii:</h3>
                <h4>
                  {mocMagazynuEnergii}
                  <span> kWh</span>
                </h4>
              </CSS.ColumnInfo>
            )}
          </CSS.InfoContainer>
        ) : null}
      </CSS.FormContainer>

      <CSS.ButtonGroup>
        <CSS.Button onClick={handlePrevKrok} disabled={isButtonDisabled}>
          Poprzedni krok
        </CSS.Button>
        <CSS.Button onClick={formik.handleSubmit} disabled={isButtonDisabled}>
          Następny krok
        </CSS.Button>
      </CSS.ButtonGroup>
    </>
  );
};

export default Krok4Form;
