import styled from "styled-components";

export const FormContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 800px) {
    flex-direction: column;
  }
`;

export const FormInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  gap: 20px;
  width: calc(65% - 48px);

  @media screen and (max-width: 800px) {
    width: 100%;
  }
`;

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;

  label,
  input,
  select {
    font-size: 14px;
    flex: 1;
    width: 50%;
    flex-grow: 1;
  }

  select,
  input {
    overflow: hidden;
    border: 1px solid #adadad;
    border-radius: 4px;
  }

  input,
  select {
    padding: 6px 4px;
  }

  label {
    margin-right: 32px;
    text-align: right;
  }

  @media screen and (max-width: 800px) {
    label {
      margin-right: 0px;
      margin-bottom: 5px;
      text-align: left;
    }

    label,
    input,
    select {
      width: 100%;
    }
  }
`;

export const FormGroupInner = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 800px) {
    flex-direction: column;
    align-items: normal;
  }
`;

export const InfoContainer = styled.div`
  width: 30%;
  border: 1px solid #52b925;
  padding: 24px;
  margin-top: 20px;

  h3,
  h4 {
    font-weight: 400;
    text-align: right;
    margin: 0;
  }

  h4 span {
    font-weight: 300;
  }

  @media screen and (max-width: 800px) {
    padding: 32px;
    width: calc(90% - 64px);
  }
`;

export const ColumnInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px 0;

  :first-of-type {
    margin-top: 0;
  }

  :last-of-type {
    margin-bottom: 0;
  }
`;

export const RowInfo = styled.div`
  display: flex;
  gap: 30px;
  margin-bottom: 16px;

  :last-of-type {
    margin-bottom: 0;
  }

  h2 {
    font-size: 18px;
    flex: 1;
    margin: 0;
  }
  h4 {
    flex: 1;
    text-align: left !important;
  }
`;
export const H3TextLeft = styled.h3`
  text-align: left !important;
`;

export const ButtonGroup = styled.div`
  font-family: "Roboto", Sans-serif;
  display: flex;
  justify-content: space-between;
`;

export const Button = styled.button.attrs({
  type: "submit",
})`
  font-size: 15px;
  font-weight: 500;
  align-self: flex-end;
  padding: 12px 24px;
  cursor: pointer;
  background: ${(props) => (props.disabled ? "gray" : "#fff")};
  color: #52ba26;
  border: 1px solid #52ba26;
  border-radius: 3px;
  transition: all 0.3s ease-in-out;

  :hover {
    background: #52ba26;
    color: #fff;
    border-color: #52ba26;
  }
`;

export const ErrorFormContainer = styled.div`
  display: flex;

  div {
    flex: 1;
    margin-right: 32px;

    @media screen and (max-width: 800px) {
      flex: 0;
      margin-right: 0;
    }
  }

  span {
    flex: 1;
    padding: 6px;
  }
`;

export const ErrorMessage = styled.span`
  color: #dc3232;
  font-size: 14px;
`;

export const InfoFormContainer = styled(ErrorFormContainer)`
  /* w ten sposob usuwam gap w rodzicu */
  margin-top: -20px;
`;

export const InfoMessage = styled.span`
  color: #52ba26;
  font-size: 14px;
`;

export const ErrorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    font-size: 24px;
    color: #dc3232;
  }
`;
