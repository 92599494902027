import * as CSS from "./Home.CSS";
import pv_icon from "../../assets/pv_icon.svg";
import hybrid_icon from "../../assets/hybrid_icon.svg";

const Home = () => {
  return (
    <CSS.Container>
      <h2>Dostępne kalkulatory:</h2>
      <ul>
        <li>
          <CSS.LinkTo to="/fw/">
            <img src={pv_icon} alt="icon"/>
            <span>Fotowoltaika - ONGRID</span>
          </CSS.LinkTo>
        </li>
        <li>
          <CSS.LinkTo to="/h/">
            <img src={hybrid_icon} alt="icon"/>
            <span>Fotowoltaika - Hybryda + Magazyn</span>
          </CSS.LinkTo>
        </li>
        {/*<li>*/}
        {/*  <Link to="/pc">Pompa ciepła</Link>*/}
        {/*</li>*/}
        {/*<li>*/}
        {/*  <Link to="/kl">Klimatyzacja</Link>*/}
        {/*</li>*/}
        {/*<li>*/}
        {/*  <Link to="/cp">Carpot</Link>*/}
        {/*</li>*/}
      </ul>
    </CSS.Container>
  );
};

export default Home;
