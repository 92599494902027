import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h1,
  h2,
  h3 {
    font-weight: normal;
  }

  h1 {
    font-size: 28px;
    margin-bottom: 32px;
  }

  h2 {
    font-size: 20px;
    margin: 0;
  }

  h3:first-of-type {
    margin-bottom: 0;
  }

  h3:last-of-type {
    margin-top: 16px;
  }

  h3 {
    font-size: 34px;
  }
`;

export const Button = styled.button.attrs({
  type: "button",
})`
  font-size: 16px;
  font-weight: 500;
  align-self: center;
  padding: 16px 32px;
  cursor: pointer;
  background-color: #fff;
  color: #52ba26;
  border: 1px solid #52ba26;
  border-radius: 24px;
  transition: all 0.3s ease-in-out;
  text-transform: uppercase;

  :hover {
    background-color: #52ba26;
    color: #fff;
  }
`;
