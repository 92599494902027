import React from "react";
import * as CSS from "./InputWithMeasure.CSS";

const InputWithMeasure = (props) => {
  const { measure, name, min, step, value, onBlur, onChange } = props;
  return (
    <CSS.Container>
      <input
        name={name}
        min={min}
        step={step}
        value={value}
        onBlur={onBlur}
        onChange={onChange}
        type="number"
      />
      <span>{measure}</span>
    </CSS.Container>
  );
};

export default InputWithMeasure;
