import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as CSS from "../krokNForm.CSS";
import axios from "axios";
import LoadingSpinner from "../../../../components/common/loadingComponent/LoadingSpinner";
import Krok3Schema from "./Krok3Schema";
import { createSearchParams, useNavigate } from "react-router-dom";
import { useRef } from "react";

const Krok3Form = ({
  apiUrl,
  prevKrok,
  nextKrok,
  setActiveTab,
  queryParams,
}) => {
  const [initialData, setInitialData] = useState({});
  const [choicesOrientacjaPaneli, setChoicesOrientacjaPaneli] = useState([]);
  const [choicesMiejsceZabudowyPaneli, setChoicesMiejsceZabudowyPaneli] =
    useState([]);
  const [choicesKatNachylenia, setChoicesKatNachylenia] = useState([]);
  const [choicesRodzajKonstrukcji, setChoicesRodzajKonstrukcji] = useState([]);

  const [miejsceZabudowyPaneli, setMiejsceZabudowyPaneli] = useState(null);
  const [orientacjaPaneli, setOrientacjaPaneli] = useState(null);

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const inputKatNachyleniRef = useRef(null);

  const navigate = useNavigate();

  const handlePrevKrok = (e) => {
    e.preventDefault();
    navigate({
      pathname: `${prevKrok}/`,
      search: `?${createSearchParams(queryParams)}`,
    });
    setActiveTab(prevKrok);
  };

  const handleNextKrok = (nextKrok) => {
    navigate({
      pathname: `${nextKrok}/`,
      search: `?${createSearchParams(queryParams)}`,
    });
    setActiveTab(nextKrok);
  };

  const handleMiejsceZabudowyPaneli = (event) => {
    let value = event.target.value;

    setMiejsceZabudowyPaneli(value);

    // przypisuje odpowiednia wartość selectowi pamiętając o wartości domyślnej
    if (value !== formik.initialValues.miejsce_zabudowy_paneli) {
      formik.setFieldValue("rodzaj_konstrukcji", null);
    } else {
      formik.setFieldValue(
        "rodzaj_konstrukcji",
        formik.initialValues.rodzaj_konstrukcji
      );
    }
    onSubmitMiejsceZabudowy(value);
  };

  const handleOrientacjaPaneli = (event) => {
    let value = event.target.value;

    setOrientacjaPaneli(value);
  };

  function handleKatNachylenia() {
    if (inputKatNachyleniRef.current != null)
      return inputKatNachyleniRef.current.value;
  }

  useEffect(() => {
    axios
      .get(apiUrl)
      .then((response) => {
        const data = response.data;

        setInitialData(data.initial_value);
        setChoicesOrientacjaPaneli(data.choices_orientacja_paneli);
        setChoicesMiejsceZabudowyPaneli(data.choices_miejsce_zabudowy_paneli);
        setChoicesKatNachylenia(data.choices_kat_nachylenia);
        setChoicesRodzajKonstrukcji(data.choices_rodzaj_konstrukcji);

        setMiejsceZabudowyPaneli(data.initial_value.miejsce_zabudowy_paneli);
        setOrientacjaPaneli(data.initial_value.orientacja_paneli);

        setIsLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        console.error(error);
        setIsLoading(false);
      });
  }, []);

  const onSubmitMiejsceZabudowy = async (value_miejsce_zabudowy) => {
    if (value_miejsce_zabudowy === "") return;

    value_miejsce_zabudowy = { miejsce_zabudowy: value_miejsce_zabudowy };
    try {
      await axios.post(apiUrl, value_miejsce_zabudowy).then((response) => {
        const data = response.data;

        setChoicesRodzajKonstrukcji(data.choices_rodzaj_konstrukcji);
      });
    } catch (error) {
      setError(error.message);
      console.error(error);
    }
  };

  const onSubmit = async (values) => {
    setIsLoading(true);

    let kat_nachylenia = handleKatNachylenia();

    // nadpisuje kat nachylenia dla grunt i dach plaski
    if (miejsceZabudowyPaneli !== "dpo") {
      values.kat_nachylenia = kat_nachylenia;
    }

    try {
      await axios.post(apiUrl, values).then((response) => {
        setIsLoading(false);
        handleNextKrok(nextKrok);
      });
    } catch (error) {
      setError(error.message);
      console.error(error);
      setIsLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      orientacja_paneli: initialData.orientacja_paneli,
      miejsce_zabudowy_paneli: initialData.miejsce_zabudowy_paneli,
      kat_nachylenia: initialData.kat_nachylenia,
      rodzaj_konstrukcji: initialData.rodzaj_konstrukcji,
    },
    enableReinitialize: true,
    validationSchema: Krok3Schema,
    onSubmit,
  });

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return (
      <CSS.ErrorContainer>
        <span>
          Nastąpił problem podczas pobierania albo wysyłaniach danych. Spróbuj
          później!
        </span>
      </CSS.ErrorContainer>
    );
  }

  return (
    <>
      <CSS.FormContainer>
        <CSS.FormInnerContainer>
          <CSS.FormGroup>
            <CSS.FormGroupInner>
              <label htmlFor="orientacja_paneli">
                Orientacja paneli względem stron świata:
              </label>
              <select
                name="orientacja_paneli"
                value={formik.values.orientacja_paneli}
                onBlur={formik.handleBlur}
                onChange={(event) => {
                  formik.handleChange("orientacja_paneli")(event);
                  handleOrientacjaPaneli(event);
                }}
              >
                <option value="">wybierz</option>
                {choicesOrientacjaPaneli.map((orientacja) => (
                  <option key={orientacja.value} value={orientacja.value}>
                    {orientacja.display_name}
                  </option>
                ))}
              </select>
            </CSS.FormGroupInner>
            {formik.touched.orientacja_paneli &&
            formik.errors.orientacja_paneli ? (
              <CSS.ErrorFormContainer>
                <div></div>
                <CSS.ErrorMessage>
                  {formik.errors.orientacja_paneli}
                </CSS.ErrorMessage>
              </CSS.ErrorFormContainer>
            ) : null}
          </CSS.FormGroup>

          <CSS.FormGroup>
            <CSS.FormGroupInner>
              <label htmlFor="miejsce_zabudowy_paneli">
                Miejsce zabudowy paneli:
              </label>
              <select
                name="miejsce_zabudowy_paneli"
                id="miejsce_zabudowy_paneli"
                value={formik.values.miejsce_zabudowy_paneli}
                onBlur={formik.handleBlur}
                onChange={(event) => {
                  formik.handleChange("miejsce_zabudowy_paneli")(event);
                  handleMiejsceZabudowyPaneli(event);
                }}
              >
                <option value="">wybierz</option>
                {choicesMiejsceZabudowyPaneli.map((miejsceZabudowy) => (
                  <option
                    key={miejsceZabudowy.value}
                    value={miejsceZabudowy.value}
                  >
                    {miejsceZabudowy.display_name}
                  </option>
                ))}
              </select>
            </CSS.FormGroupInner>
            {formik.touched.miejsce_zabudowy_paneli &&
            formik.errors.miejsce_zabudowy_paneli ? (
              <CSS.ErrorFormContainer>
                <div></div>
                <CSS.ErrorMessage>
                  {formik.errors.miejsce_zabudowy_paneli}
                </CSS.ErrorMessage>
              </CSS.ErrorFormContainer>
            ) : null}
          </CSS.FormGroup>
          {(() => {
            if (miejsceZabudowyPaneli) {
              if (miejsceZabudowyPaneli === "dpo") {
                return (
                  <>
                    <CSS.FormGroup>
                      <CSS.FormGroupInner>
                        <label htmlFor="kat_nachylenia">
                          Kąt nachylenia dachu:
                        </label>
                        <select
                          name="kat_nachylenia"
                          value={formik.values.kat_nachylenia || ""}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange("kat_nachylenia")}
                        >
                          <option value="">wybierz</option>
                          {choicesKatNachylenia.map((kat) => (
                            <option key={kat.value} value={kat.value}>
                              {kat.display_name}
                            </option>
                          ))}
                        </select>
                      </CSS.FormGroupInner>
                      {formik.touched.kat_nachylenia &&
                      formik.errors.kat_nachylenia ? (
                        <CSS.ErrorFormContainer>
                          <div></div>
                          <CSS.ErrorMessage>
                            {formik.errors.kat_nachylenia}
                          </CSS.ErrorMessage>
                        </CSS.ErrorFormContainer>
                      ) : null}
                    </CSS.FormGroup>
                    <CSS.FormGroup>
                      <CSS.FormGroupInner>
                        <label htmlFor="rodzaj_konstrukcji">
                          Rodzaj konstrukcji:
                        </label>
                        <select
                          name="rodzaj_konstrukcji"
                          value={formik.values.rodzaj_konstrukcji || ""}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange("rodzaj_konstrukcji")}
                        >
                          <option value="">wybierz</option>
                          {choicesRodzajKonstrukcji.map((konstrukcja) => {
                            return (
                              <option
                                key={konstrukcja.id}
                                value={konstrukcja.id}
                              >
                                {konstrukcja.display_name}
                              </option>
                            );
                          })}
                        </select>
                      </CSS.FormGroupInner>
                      {formik.touched.rodzaj_konstrukcji &&
                      formik.errors.rodzaj_konstrukcji ? (
                        <CSS.ErrorFormContainer>
                          <div></div>
                          <CSS.ErrorMessage>
                            {formik.errors.rodzaj_konstrukcji}
                          </CSS.ErrorMessage>
                        </CSS.ErrorFormContainer>
                      ) : null}
                    </CSS.FormGroup>
                  </>
                );
              } else if (
                miejsceZabudowyPaneli === "dpl" &&
                orientacjaPaneli !== "S"
              ) {
                return (
                  <>
                    <CSS.FormGroup>
                      <CSS.FormGroupInner>
                        <input
                          ref={inputKatNachyleniRef}
                          value="20"
                          type="hidden"
                        />
                      </CSS.FormGroupInner>
                      <CSS.InfoFormContainer>
                        <div></div>
                        <CSS.InfoMessage>
                          Panele PV zostaną ustawione na konstrukcji wsporczej
                          pod kątem 15-20 stopni
                        </CSS.InfoMessage>
                      </CSS.InfoFormContainer>
                    </CSS.FormGroup>
                    <CSS.FormGroup>
                      <CSS.FormGroupInner>
                        <label htmlFor="rodzaj_konstrukcji">
                          Rodzaj konstrukcji:
                        </label>
                        <select
                          name="rodzaj_konstrukcji"
                          value={formik.values.rodzaj_konstrukcji || ""}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange("rodzaj_konstrukcji")}
                        >
                          <option value="">wybierz</option>
                          {choicesRodzajKonstrukcji.map((konstrukcja) => {
                            return (
                              <option
                                key={konstrukcja.id}
                                value={konstrukcja.id}
                              >
                                {konstrukcja.display_name}
                              </option>
                            );
                          })}
                        </select>
                      </CSS.FormGroupInner>
                      {formik.touched.rodzaj_konstrukcji &&
                      formik.errors.rodzaj_konstrukcji ? (
                        <CSS.ErrorFormContainer>
                          <div></div>
                          <CSS.ErrorMessage>
                            {formik.errors.rodzaj_konstrukcji}
                          </CSS.ErrorMessage>
                        </CSS.ErrorFormContainer>
                      ) : null}
                    </CSS.FormGroup>
                  </>
                );
              } else {
                return (
                  <>
                    <CSS.FormGroup>
                      <CSS.FormGroupInner>
                        <input
                          ref={inputKatNachyleniRef}
                          value="30"
                          type="hidden"
                        />
                      </CSS.FormGroupInner>
                      <CSS.InfoFormContainer>
                        <div></div>
                        <CSS.InfoMessage>
                          Panele PV zostaną ustawione na konstrukcji wsporczej
                          pod kątem 30 stopni
                        </CSS.InfoMessage>
                      </CSS.InfoFormContainer>
                    </CSS.FormGroup>
                    <CSS.FormGroup>
                      <CSS.FormGroupInner>
                        <label htmlFor="rodzaj_konstrukcji">
                          Rodzaj konstrukcji:
                        </label>
                        <select
                          name="rodzaj_konstrukcji"
                          value={formik.values.rodzaj_konstrukcji || ""}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange("rodzaj_konstrukcji")}
                        >
                          <option value="">wybierz</option>
                          {choicesRodzajKonstrukcji.map((konstrukcja) => {
                            return (
                              <option
                                key={konstrukcja.id}
                                value={konstrukcja.id}
                              >
                                {konstrukcja.display_name}
                              </option>
                            );
                          })}
                        </select>
                      </CSS.FormGroupInner>
                      {formik.touched.rodzaj_konstrukcji &&
                      formik.errors.rodzaj_konstrukcji ? (
                        <CSS.ErrorFormContainer>
                          <div></div>
                          <CSS.ErrorMessage>
                            {formik.errors.rodzaj_konstrukcji}
                          </CSS.ErrorMessage>
                        </CSS.ErrorFormContainer>
                      ) : null}
                    </CSS.FormGroup>
                  </>
                );
              }
            } else {
              return null;
            }
          })()}
        </CSS.FormInnerContainer>
      </CSS.FormContainer>

      <CSS.ButtonGroup>
        <CSS.Button onClick={handlePrevKrok}>Poprzedni krok</CSS.Button>
        <CSS.Button onClick={formik.handleSubmit}>Następny krok</CSS.Button>
      </CSS.ButtonGroup>
    </>
  );
};

export default Krok3Form;
