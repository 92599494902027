import styled from "styled-components";
import {Link} from "react-router-dom";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 80px;
    
    h2 {
        font-size: 32px;
    }
    
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        text-decoration: none;
        font-size: 24px;
    }
    
    li {
        padding: 8px 0;
    }

    @media screen and (max-width: 600px) {
        font-size: 24px;
        padding: 0 20px;

        h2 {
            font-size: 24px;
        }
    }
`;

export const LinkTo = styled(Link)`
    transition: all 0.2s ease-in-out;
    overflow: hidden;

    max-width: 600px;
    display: flex;
    align-items: center;
    color: black;
    text-decoration: None;
    border: 2px solid #e0e0e0;
    border-radius: 8px;
    
    img {
        height: 60px;
        width: auto;
        margin-right: 16px;
        padding: 8px;
    }

    :hover {
        border-color: rgba(82, 186, 38, 0.2);
        color: #52ba26;
    }

    @media screen and (max-width: 600px) {
        width: 100%;
        font-size: 16px;
    }
`;
