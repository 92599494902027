import styled from "styled-components";
import { Link } from "react-router-dom";

export const TabsContainer = styled.div`
  font-family: "Roboto", Sans-serif;
  max-width: 1200px;
  padding: 40px;
  margin: 0 auto;

  @media screen and (max-width: 1200px) {
    max-width: 970px;
  }
  @media screen and (max-width: 500px) {
    padding: 24px 16px;
  }
`;

export const TabNav = styled.ul`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  margin-bottom: 30px;
  padding-left: 0;
  background: #f4f4f4;
  border: 1px solid #e0e0e0;

  @media screen and (max-width: 992px) {
    grid-template-columns: repeat(3, 1fr);
  }

  li {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }

  li:hover {
    background: rgba(82, 186, 38, 0.2);
  }

  li.active {
    background: #52ba26;
    color: #fff;
  }
`;

export const TabLink = styled(Link)`
  display: block;
  width: 100%;
  padding: 16px 24px;
  font-size: 15px;
  text-decoration: none;
  color: inherit;

  @media screen and (max-width: 500px) {
    padding: 8px;
  }
  @media screen and (max-width: 350px) {
    padding: 8px 4px;
  }
`;

export const TabsContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;
