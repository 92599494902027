import * as Yup from "yup";

const Krok4Schema = Yup.object().shape({
  typ_modulu: Yup.number().required("To pole jest wymagane!"),
  typ_falownika: Yup.number().required("To pole jest wymagane!"),
  magazyn_energii: Yup.string(),
  typ_magazynu: Yup.string().when("magazyn_energii", (val) => {
    if (val[0] == "true")
      return Yup.string().required("To pole jest wymagane!");
    return Yup.number().nullable();
  }),
  edit_installation: Yup.string().required("To pole jest wymagane!"),
  ilosc_modulow_uzytkownik: Yup.string().when("edit_installation", (val) => {
    if (val[0] == "true")
      return Yup.number()
        .integer("Podaj liczbę całkowitą.")
        .min(1, "Podaj wartość większą lub równą 1")
        .required("To pole jest wymagane!");
    return Yup.number().nullable();
  }),
});

export default Krok4Schema;
